// src/App.js
import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing.js";
import LandingLoadingPage from "./pages/landing loading.js";
import GirassolPage from "./pages/girassol.js";
import FilosofiaPage from "./pages/filosofia.js";
import GaleriaPage from "./pages/galeria.js";
import ContactosPage from "./pages/contactos.js";
import RestaurantePage from "./pages/restaurante.js";
import ClipppingPage from "./pages/clipping.js";
import EmentasPage from "./pages/ementas.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      {/*<Route path="/" element={<LandingLoadingPage />} />*/}
        <Route path="/" element={<LandingPage />} />
        <Route path="/girassol" element={<GirassolPage />} />
        <Route path="/filosofia" element={<FilosofiaPage />} />
        <Route path="/restaurante" element={<RestaurantePage />} />
        <Route path="/clipping" element={<ClipppingPage />} />
        <Route path="/galeria" element={<GaleriaPage />} />
        <Route path="/contactos" element={<ContactosPage />} />
        <Route path="/ementas" element={<EmentasPage />} />
      </Routes> 
    </BrowserRouter>
  );
}

export default App;
