// src/components/Navbar.js
import React, { useState, useEffect } from "react";
import { useLanguage } from "./LanguageContext";
import { Link } from "react-router-dom";
import logo from "../images/Logo.png";

function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    // Load language data based on the selected language
    import(`../languages/${language}.json`).then((data) => {
      setLanguageData(data);
    });
  }, [language]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuStyle = {
    left: 0,
    top: 0,
    width: "40%",
    backgroundColor: "#ffc52d",
  };

  // Media query for screens smaller than 576px
  const smallScreenStyle = {
    width: "100%",
  };

  // Combine the styles based on the screen width
  const combinedStyle =
    window.innerWidth < 576 ? { ...menuStyle, ...smallScreenStyle } : menuStyle;

  function LanguageDropdown() {
    const { changeLanguage } = useLanguage();

    return (
      <li className="nav-item dropdown ml-3">
        <span
          className="text-white nav-link dropdown-toggle"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {language.toUpperCase()}
        </span>
        <div className="dropdown-menu dropdown-menu-right">
          <span className="dropdown-item" onClick={() => changeLanguage("en")}>
            EN
          </span>

          <span className="dropdown-item" onClick={() => changeLanguage("pt")}>
            PT
          </span>
        </div>
      </li>
    );
  }

  return (
    <nav
      className="navbar navbar-expand navbar-dark position-absolute w-100 pl-5 pr-0 border-bottom border-white pt-3"
      style={{
        zIndex: 100,
        backgroundColor: props.color,
      }}
    >
      <div className="row w-100">
        <div className="col">
          <a
            className="text-white nav-link"
            href="javascript:void(0)"
            onClick={toggleMenu}
            style={{ width: "fit-content" }}
          >
            <span className="navbar-toggler-icon align-bottom mr-1"></span>
            MENU
          </a>
        </div>
        <div className="col text-center d-none d-sm-block">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt="Logo"
              className="d-block mx-auto"
              style={{ width: "100px" }}
            />
          </Link>
        </div>
        <div className="col text-end ">
          <ul className="navbar-nav flex-row-reverse">
            <LanguageDropdown />
          </ul>
        </div>
      </div>

      <div
        className={`position-fixed pt-3 px-5 h-100 ${
          menuOpen ? "open" : "closed"
        }`}
        style={combinedStyle}
      >
        <a
          className="text-white nav-link mb-4"
          href="javascript:void(0)"
          onClick={toggleMenu}
          style={{ width: "fit-content" }}
        >
          <span className="pr-1">X</span>
          {languageData.menuFechar}
        </a>
        <hr
          className="position-absolute w-100"
          style={{ borderTop: "1px solid white", top: "52px", left: "0px" }}
        ></hr>
        <div className="d-block d-sm-none">
          <MenuItem text={languageData.menu1} link="/" />
        </div>
        <MenuItem text={languageData.menu2} link="/girassol" />
        <MenuItem text={languageData.menu3} link="/filosofia" />
        <MenuItem text={languageData.menu6} link="/restaurante" />
        <MenuItem text={languageData.menu8} link="/ementas" />
        <MenuItem text={languageData.menu4} link="/galeria" />
        <MenuItem text={languageData.menu7} link="/clipping" />
        {/* <MenuItem text={languageData.charutos} /> */}
        <MenuItem text={languageData.menu5} link="/contactos" />
      </div>
    </nav>
  );
}

function MenuItem({ text, link }) {
  return (
    <Link
      className="text-white nav-link"
      style={{ width: "fit-content" }}
      to={link}
    >
      {text}
    </Link>
  );
}

export default Navbar;
