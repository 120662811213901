// src/pages/contactos.js
import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/contactos.jpg";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import tripadvisor from "../images/tripadvisor.png";
import { useLanguage } from "../components/LanguageContext";

const ContactosPage = () => {
  const { language } = useLanguage();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    // Load language data based on the selected language
    import(`../languages/${language}.json`).then((data) => {
      setLanguageData(data);
    });
  }, [language]);
  return (
    <div>
      <Navbar /> {/* Include the Navbar component */}
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center left",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            left: 0,
            top: 0,
            width: "40%",
            backgroundColor: "#ffc52d",
            paddingTop: "7rem",
            paddingInline: "4vw",
          }}
          className="restaurante position-fixed h-100 text-left text-white"
        >
          <h1 style={{ fontSize: "30px" }} className="text-uppercase pb-3">
            {languageData.contactosTitulo}
          </h1>
          <div className="textoCorpo">
            <h1 className="subTitulos font-weight-bold">{languageData.contactosMorada}</h1>
            <p
              className="pb-4"
              dangerouslySetInnerHTML={{
                __html: languageData.contactosMoradaTexto,
              }}
            ></p>
            <h1 className="subTitulos font-weight-bold">{languageData.contactosHorario}</h1>
            <p
              className="pb-1"
              dangerouslySetInnerHTML={{
                __html: languageData.contactosHorarioTexto1,
              }}
            ></p>
            <p
              className="pb-4"
              dangerouslySetInnerHTML={{
                __html: languageData.contactosHorarioTexto2,
              }}
            ></p>
            <h1 className="subTitulos font-weight-bold">{languageData.contactosContactos}</h1>

            <a className="textoCorpo text-white text-decoration-underline pr-3" href="tel:+351212891820">
              +351 212 891 820
            </a>
            <a className="textoCorpo text-white text-decoration-underline" href="tel:++351910288999">
              +351 910 288 999
            </a>
            <a className="textoCorpo text-white text-decoration-underline" href="mailto:reservas@girassolrestaurante.pt">
              <br></br>
              reservas@girassolrestaurante.pt
            </a>
          </div>
          <br></br>
          <div className="d-flex text-white pt-4" style={{ filter: "invert(1)" }}>
            <a
              href="https://www.tripadvisor.pt/Restaurant_Review-g658385-d1807472-Reviews-Restaurante_Girassol-Montijo_Setubal_District_Alentejo.html"
              target="_blank"
            >
              <img src={tripadvisor} alt="Tripadvisor" style={{ width: "32px" }}></img>
            </a>

            <a href="https://www.facebook.com/restaurantegirassolsarilhos" target="_blank">
              <img src={facebook} alt="Facebook" style={{ width: "32px" }} className="mx-1"></img>
            </a>

            <a href="https://www.instagram.com/girassol_restaurante/" target="_blank">
              <img src={instagram} alt="Instagram" style={{ width: "32px" }}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactosPage;
