// src/pages/clipping.js
import React from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/restaurante.jpg";
import pdfEmentaRestaurante from "../Ementa/EmentaRestaurante.pdf";
import pdfEmentaSobremesas from "../Ementa/EmentaSobremesas.pdf";
import pdfEmentaGelados from "../Ementa/EmentaGelados.pdf";

const buttonStyle = {
  backgroundColor: "rgb(255, 197, 45)",
  borderColor: "rgb(255, 197, 45)",
  color: "#fff",
};

const buttonStyleSmall = {
  "margin-top": "7rem",
};

const EmentasPage = () => {
  return (
    <div>
      <Navbar />
      <div
        className="clipping"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0" style={buttonStyleSmall}>
          {" "}
          {/* Add mt-200 class here */}
          <div className="row justify-content-center mt-sm-5">
            <div className="col-sm-10 col-md-6 col-lg-4 mb-3">
              <a href={pdfEmentaRestaurante} className="btn btn-lg btn-primary w-100" style={buttonStyle} target="_blank" rel="noopener noreferrer">
                Carta Restaurante
              </a>
            </div>
            <div className="col-sm-10 col-md-6 col-lg-4 mb-3">
              {/* Button to download PDF */}
              <a href={pdfEmentaSobremesas} className="btn btn-lg btn-primary w-100" style={buttonStyle} target="_blank" rel="noopener noreferrer">
                Carta Sobremesas
              </a>
            </div>
            <div className="col-sm-10 col-md-6 col-lg-4 mb-3">
              {/* Button to download PDF */}
              <a href={pdfEmentaGelados} className="btn btn-lg btn-primary w-100" style={buttonStyle} target="_blank" rel="noopener noreferrer">
                Carta Gelados
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmentasPage;
