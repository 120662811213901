// src/pages/landing.js
import React from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/background-image.jpg";
import logoText from "../images/logo_girassol.svg";

const LandingPage = () => {
  return (
    <div>
      <Navbar /> {/* Include the Navbar component */}
      <div
        className="landing"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img className="textGirassol" style={{ marginLeft: "15%", marginRight: "15%", width: "100%" }} src={logoText} alt="Background Imagem"></img>
      </div>
    </div>
  );
};

export default LandingPage;
