// src/pages/clipping.js
import React from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/restaurante.jpg";
import logoText from "../images/logo_girassol.svg";
import foto1 from "../images/clipping_1.png";
import foto2 from "../images/clipping_2.jpg";
import foto3 from "../images/clipping_3.jpg";

const ClippingPage = () => {
  return (
    <div>
      <Navbar /> {/* Include the Navbar component */}
      <div
        className="clipping"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="row align-items-center text-justify">
          <div class="col-md my-5 text-center">
            <img className="rounded w-100 " src={foto1} alt="foto1"></img>
          </div>
          <div class="col-md my-5 text-center">
            <img className="rounded w-50 " src={foto2} alt="foto2"></img>
          </div>
          <div class="col-md my-5 text-center">
            <img className="rounded w-75 " src={foto3} alt="foto3"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClippingPage;
