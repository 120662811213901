// src/pages/girassol.js
import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/FOTO1.png";
import { useLanguage } from "../components/LanguageContext";
import foto2 from "../images/FOTO 2.jpg";
import ScrollToTop from "react-scroll-to-top";

const GirassolPage = () => {
  const { language } = useLanguage();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    // Load language data based on the selected language
    import(`../languages/${language}.json`).then((data) => {
      setLanguageData(data);
    });
  }, [language]);

  return (
    <div>
      <Navbar /> {/* Include the Navbar component */}
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-white" style={{ paddingTop: "9rem", paddingInline: "8vw" }}>
          <h1 style={{ fontSize: "50px" }} className="Titulos text-uppercase pb-3">
            girassol
          </h1>
          <p
            className="text-justify textoCorpo"
            style={{ lineHeight: 2, fontSize: "18px" }}
            dangerouslySetInnerHTML={{
              __html: languageData.girassolTexto,
            }}
          ></p>
          <p
            className="text-justify textoCorpo mb-0 pb-5"
            style={{ lineHeight: 2, fontSize: "18px" }}
            dangerouslySetInnerHTML={{
              __html: languageData.girassolTexto2,
            }}
          ></p>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffc52d" }}>
        <div className="text-white " style={{ paddingInline: "8vw" }}>
          <h1 style={{ fontSize: "50px" }} className="Titulos text-uppercase pb-3 pt-3">
            {languageData.girassolTexto3}
          </h1>
          <div className="row">
            <div className="col-lg border-right">
              {" "}
              {/* Added border-right class here */}
              <p className="textoCorpo"> ARMINDA BARATA</p>
              <p className="textoCorpo">{languageData.content1}</p>
              <p className="textoCorpo">{languageData.content2}</p>
              <p className="textoCorpo">{languageData.content3}</p>
            </div>
            <div className="col-lg-9">
              <p className="textoCorpo">SANDRA BARATA</p>
              <p className="textoCorpo">{languageData.content4}</p>
              <p className="textoCorpo">{languageData.content5}</p>
              <p className="textoCorpo">{languageData.content6}</p>
              <p className="textoCorpo">{languageData.content7}</p>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-sm-12 col-md-6 mb-4 mb-md-0">
              <img className="rounded img-fluid" src={foto2} alt="foto2" style={{ maxWidth: "100%", height: "auto", aspectRatio: "16 / 9" }} />
            </div>
            <div className="col-sm-12 col-md-6 mb-5 mb-md-0">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/-gebMQZuAvI" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth />
    </div>
  );
};

export default GirassolPage;
