// src/pages/girassol.js
import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar"; // Import the Navbar component
import backgroundImage from "../images/FOTO1.png";
import { useLanguage } from "../components/LanguageContext";
import ScrollToTop from "react-scroll-to-top";
import fotoFilosofia1 from "../images/Filosofia/FILOSOFIA1.jpg";
import fotoFilosofia2 from "../images/Filosofia/FILOSOFIA2.jpg";
import fotoFilosofia3 from "../images/Filosofia/FILOSOFIA3.jpg";
import fotoMemoria1 from "../images/Filosofia/Memoria/MEMORIA1.jpg";
import fotoMemoria2 from "../images/Filosofia/Memoria/MEMORIA2.jpg";
import fotoMemoria3 from "../images/Filosofia/Memoria/MEMORIA3.jpg";
import fotoMemoria4 from "../images/Filosofia/Memoria/MEMORIA4.jpg";
import fotoTradicao1 from "../images/Filosofia/Tradição/TRADIÇAO1.jpg";
import fotoProduto1 from "../images/Filosofia/Produto/PRODUTO1.jpeg";
import fotoVinho1 from "../images/Filosofia/Vinho/VINHO1.jpg";
import fotoInovacao1 from "../images/Filosofia/Inovacao/INOVACAO1.jpg";
import fotoDoce1 from "../images/Filosofia/Doce/DOCE1.jpg";
import fotoDoce2 from "../images/Filosofia/Doce/DOCE2.jpg";
import fotoDoce3 from "../images/Filosofia/Doce/DOCE3.jpg";
import fotoLiberdade1 from "../images/Filosofia/Liberdade/LIBERDADE1.jpg";
import fotoLiberdade2 from "../images/Filosofia/Liberdade/LIBERDADE2.jpg";
import fotoLiberdade3 from "../images/Filosofia/Liberdade/LIBERDADE3.jpg";
import fotoAtrevimento1 from "../images/Filosofia/Atrevimento/ATREVIMENTO1.jpg";
import fotoAtrevimento2 from "../images/Filosofia/Atrevimento/ATREVIMENTO2.jpg";
import fotoHumor1 from "../images/Filosofia/Humor/HUMOR1.jpg";
import fotoHumor2 from "../images/Filosofia/Humor/HUMOR2.jpg";
import fotoHumor3 from "../images/Filosofia/Humor/HUMOR3.jpg";
import fotoHumor4 from "../images/Filosofia/Humor/HUMOR4.jpg";

const FilosofiaPage = () => {
  const { language } = useLanguage();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    // Load language data based on the selected language
    import(`../languages/${language}.json`).then((data) => {
      setLanguageData(data);
    });
  }, [language]);

  return (
    <div>
      <Navbar /> {/* Include the Navbar component */}
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div style={{ backgroundColor: "#ffc52d" }}>
        <div
          style={{
            left: 0,
            top: 0,
            width: "100%",
            backgroundColor: "black",
            paddingTop: "7rem",
            paddingInline: "4vw",
          }}
          className="restaurante text-left text-white"
        >
          <h1 style={{ fontSize: "30px" }} className="Titulos text-uppercase">
            {languageData.restauranteTitulo}
          </h1>
          <p
            className="textoCorpo text-justify mb-0 pb-4"
            style={{ lineHeight: 2, fontSize: "18px" }}
            dangerouslySetInnerHTML={{ __html: languageData.restauranteTexto }}
          ></p>
        </div>

        <div className="text-white " style={{ paddingInline: "8vw" }}>
          <div className="row mx-0">
            <div className="col-md my-4 p-4">
              <div className="row pb-4 text-center overflow-hidden">
                <img alt="fotoFilosofia1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoFilosofia3} />
              </div>
              <div className="row pb-4 text-center overflow-hidden">
                <img alt="fotoLiberdade3" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoFilosofia2} />
              </div>
            </div>
            <div className="col-md my-4 p-4 overflow-hidden d-flex align-items-center">
              <div className="row pb-4 text-center" style={{ display: "contents" }}>
                <img alt="fotoLiberdade1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoFilosofia1} />
              </div>
            </div>
          </div>

          {/* Memória */}
          <div class="row align-items-center text-justify pb-5 mt-5">
            <div class="col-md my-4 p-4">
              <h3>{languageData.memoriatitle}</h3>
              <p className="textoCorpo">{languageData.memoriatext1}</p>
              <p className="textoCorpo">{languageData.memoriatext2}</p>
              <p className="textoCorpo">{languageData.memoriatext3}</p>
            </div>
            <div className="col-sm coluna" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center">
                <img alt="fotoMemoria1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoMemoria1} />
              </div>
              <div className="row pb-4 text-center">
                <img alt="fotoMemoria2" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoMemoria2} />
              </div>
            </div>
            <div className="col-sm ml-3 coluna" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center">
                <img alt="fotoMemoria3" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoMemoria3} />
              </div>
              <div className="row pb-4 text-center">
                <img alt="fotoMemoria4" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoMemoria4} />
              </div>
            </div>
          </div>
          {/* Fim Memória */}

          {/* Tradição */}
          <div class="row align-items-center text-justify pb-5">
            <div class="col-md my-4 p-4">
              <img alt="fotoTradicao1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoTradicao1} />
            </div>
            <div class="col-md my-4 p-4">
              <h3>{languageData.tradicatitle}</h3>
              <p className="textoCorpo">{languageData.tradicatext1}</p>
              <p className="textoCorpo">{languageData.tradicatext2}</p>
              <p className="textoCorpo">{languageData.tradicatext3}</p>
              <p className="textoCorpo">{languageData.tradicatext4}</p>
            </div>
          </div>
          {/* Fim Tradição */}

          {/* Produto */}
          <div class="row align-items-center text-justify pb-5">
            <div class="col-md my-4 p-4">
              <h3>{languageData.produtotitle}</h3>
              <p className="textoCorpo">{languageData.produtotext1}</p>
              <p className="textoCorpo">{languageData.produtotext2}</p>
            </div>
            <div class="col-md my-4 p-4 text-center">
              <img alt="fotoProduto1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoProduto1} />
            </div>
          </div>
          {/* Fim Produto */}

          {/* Vinho */}
          <div class="row align-items-center text-justify pb-5">
            <div class="col-md my-4 p-4 text-center">
              <img alt="fotoVinho1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoVinho1} />
            </div>
            <div class="col-md my-4 p-4">
              <h3>{languageData.vinhotitle}</h3>
              <p className="textoCorpo">{languageData.vinhotext1}</p>
              <p className="textoCorpo">{languageData.vinhotext2}</p>
            </div>
          </div>
          {/* Fim Vinho */}

          {/* Inovação */}
          <div class="row align-items-center text-justify pb-5">
            <div class="col-md my-4 p-4">
              <h3>{languageData.inovacaotitle}</h3>
              <p className="textoCorpo">{languageData.inovacaotext1}</p>
              <p className="textoCorpo">{languageData.inovacaotext2}</p>
              <p className="textoCorpo">{languageData.inovacaotext3}</p>
              <p className="textoCorpo">{languageData.inovacaotext4}</p>
            </div>
            <div className="col-sm ml-3 text-center mb-5" style={{ height: "38vw" }}>
              <img alt="fotoInovacao1" className="w-50 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoInovacao1} />
            </div>
          </div>
          {/* Fim Inovação */}

          {/* Doce */}
          <div class="row align-items-center text-justify pb-5 mt-5">
            <div className="col-sm coluna" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center">
                <img alt="fotoDoce1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoDoce1} />
              </div>
              <div className="row pb-4 text-center">
                <img alt="fotoDoce3" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoDoce3} />
              </div>
            </div>
            <div className="col-sm ml-3 coluna overflow-hidden d-flex align-items-center" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center">
                <img alt="fotoDoce2" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoDoce2} />
              </div>
            </div>
            <div class="col-md my-4 p-4">
              <h3>{languageData.docetitle}</h3>
              <p className="textoCorpo">{languageData.docetext1}</p>
            </div>
          </div>
          {/* Fim Doce */}

          {/* Liberdade */}
          <div class="row align-items-center text-justify pb-5 mt-5">
            <div class="col-md my-4 p-4">
              <h3>{languageData.liberdadetitle}</h3>
              <p className="textoCorpo">{languageData.liberdadetext1}</p>
              <p className="textoCorpo">{languageData.liberdadetext2}</p>
              <p className="textoCorpo">{languageData.liberdadetext3}</p>
              <p className="textoCorpo">{languageData.liberdadetext4}</p>
            </div>
            <div className="col-sm coluna" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center " style={{ display: "contents" }}>
                <img alt="fotoLiberdade1" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoLiberdade1} />
              </div>
            </div>
            <div className="col-sm coluna mt-5" style={{ height: "38vw" }}>
              <div className="row pb-4 text-center overflow-hidden">
                <img alt="fotoLiberdade2" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoLiberdade2} />
              </div>
              <div className="row pb-4 text-center overflow-hidden">
                <img alt="fotoLiberdade3" className="w-75 mb-0 rounded" style={{ objectFit: "cover" }} src={fotoLiberdade3} />
              </div>
            </div>
          </div>
          {/* Fim Liberdade */}

          {/* Atrevimento */}
          <div class="row text-justify ">
            <div class="col-md my-4 p-4">
              <h3>{languageData.atrevimentotitle}</h3>
              <p className="textoCorpo">{languageData.atrevimentotext1}</p>
              <p className="textoCorpo">{languageData.atrevimentotext2}</p>
            </div>
          </div>
          <div className="row pb-5 text-center">
            <div className="col" style={{ height: "38vw" }}>
              <img className="h-100 rounded" src={fotoAtrevimento1} alt="fotoAtrevimento1" />
            </div>

            <div className="col" style={{ height: "38vw" }}>
              <img className="h-100 rounded" src={fotoAtrevimento2} alt="fotoAtrevimento2" />
            </div>
          </div>
          {/* Fim Atrevimento */}

          {/* Humor */}
          <div class="row text-justify ">
            <div class="col-md my-4 p-4">
              <h3>{languageData.humortitle}</h3>
              <p className="textoCorpo">{languageData.humortext1}</p>
              <p className="textoCorpo">{languageData.humortext2}</p>
              <p className="textoCorpo">{languageData.humortext3}</p>
            </div>
          </div>

          <div className="row pb-5 ">
            <div className="col mb-4" style={{ height: "38vw" }}>
              <img className="h-75 rounded" src={fotoHumor1} alt="fotoHumor1" />
            </div>
            <div className="col text-center overflow-hidden" style={{ height: "38vw" }}>
              <img className="h-75 rounded " src={fotoHumor2} alt="fotoHumor2" />
            </div>
          </div>
          <div className="row pb-5 ">
            <div className="col mb-4" style={{ height: "38vw" }}>
              <img className="h-100 rounded" src={fotoHumor3} alt="fotoHumor3" />
            </div>
            <div className="col" style={{ height: "38vw" }}>
              <img className="h-100 rounded" src={fotoHumor4} alt="fotoHumor4" />
            </div>
          </div>
          {/* Fim Humor */}
        </div>
      </div>
      <ScrollToTop smooth />
    </div>
  );
};

export default FilosofiaPage;
