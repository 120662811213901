import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import "yet-another-react-lightbox/styles.css";

export default function GaleriaPage() {
  const [index, setIndex] = useState(-1);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiKey = "AIzaSyAxLJl3aDECxotZNO_Itqs0VFe-NafEKys";
  const parentFolderId = "1d_l9_0st_KyPDOeEAVlnGIsTaptglGwY"; // ID da pasta raiz no Google Drive

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files?q='${parentFolderId}' in parents and mimeType='application/vnd.google-apps.folder'&key=${apiKey}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Classificar as pastas em ordem alfabética pelo nome
        const sortedFolders = data.files.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        const fetchedFolders = [];

        // Para cada pasta encontrada, buscar suas fotos
        for (const folder of sortedFolders) {
          const folderId = folder.id;
          let folderName = folder.name;

          // Remover o prefixo numérico
          folderName = folderName.split("_")[1];

          const photosResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q='${folderId}' in parents&key=${apiKey}`);

          if (!photosResponse.ok) {
            throw new Error("Network response was not ok");
          }

          const photosData = await photosResponse.json();
          const filePromises = photosData.files.map((file) => {
            const fileId = file.id;
            const getFileMetadataUrl = `https://www.googleapis.com/drive/v2/files/${fileId}?key=${apiKey}`;

            return fetch(getFileMetadataUrl)
              .then((response) => response.json())
              .then((metadata) => {
                const width = metadata.imageMediaMetadata ? metadata.imageMediaMetadata.width : 1;
                const height = metadata.imageMediaMetadata ? metadata.imageMediaMetadata.height : 1;

                return {
                  src: `https://drive.lienuc.com/uc?id=${fileId}`,
                  width,
                  height,
                };
              });
          });

          const fetchedPhotos = await Promise.all(filePromises);

          fetchedFolders.push({
            name: folderName,
            photos: fetchedPhotos,
          });
        }

        // Definir as pastas e fotos
        setFolders(fetchedFolders);
      } catch (error) {
        console.error("Error fetching folders and photos:", error);
      } finally {
        // Definir isLoading como falso após a última renderização
        setIsLoading(false);
      }
    };

    fetchFolders();

    return () => {
      setIsLoading(false);
    };
  }, [parentFolderId, apiKey]);

  return (
    <>
      <Navbar color="#ffc52d" />
      <div style={{ paddingTop: "69px" }}></div>

      {isLoading && (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
          <div className="spinner-border" role="status"></div>
        </div>
      )}

      <div className="container">
        {folders.map((folder, index) => (
          <div key={index}>
            <h1 className="text-center m-4">{folder.name}</h1>
            <div className="row justify-content-center">
              {folder.photos.map((photo, idx) => (
                <div key={idx} className="col-md-4 d-flex align-items-center" style={{ padding: "5px", position: "relative" }}>
                  <img src={photo.src} alt={`Photo ${idx}`} crossOrigin="anonymous" onClick={() => setIndex(idx)} className="img-fluid" />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
