import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import { useLanguage } from "../components/LanguageContext";
import ScrollToTop from "react-scroll-to-top";
import fotoEquipa1 from "../images/Restaurante/Equipa/EQUIPA1.jpg";
import fotoEquipa2 from "../images/Restaurante/Equipa/EQUIPA2.jpg";
import fotoEquipa3 from "../images/Restaurante/Equipa/EQUIPA3.jpg";
import fotoEquipa4 from "../images/Restaurante/Equipa/EQUIPA4.jpg";
import fotoSalas1 from "../images/Restaurante/Salas/SALAS1.jpeg";
import fotoSalas2 from "../images/Restaurante/Salas/SALAS2.jpeg";
import fotoSalas3 from "../images/Restaurante/Salas/SALAS3.jpg";
import fotoSalas4 from "../images/Restaurante/Salas/SALAS4.jpg";
import fotoSalas5 from "../images/Restaurante/Salas/SALAS5.jpg";

const RestaurantePage = () => {
  const { language } = useLanguage();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    import(`../languages/${language}.json`).then((data) => {
      setLanguageData(data);
    });
  }, [language]);

  return (
    <div>
      <Navbar />
      <div
        style={{
          left: 0,
          top: 0,
          width: "100%",
          backgroundColor: "black",
          paddingTop: "7rem",
          paddingInline: "4vw",
        }}
        className="restaurante text-left text-white"
      >
        {/* Equipa */}
        <h1 style={{ fontSize: "30px" }} className="Titulos text-uppercase">
          {languageData.equipaTitulo}
        </h1>
        <div className="row pb-5 ">
          <div className="col mb-4 text-center" style={{ height: "38vw" }}>
            <img className="h-100 rounded" src={fotoEquipa1} alt="fotoEquipa1" />
          </div>
        </div>
        <div className="row pb-5 ">
          <div className="col text-center overflow-hidden" style={{ height: "38vw" }}>
            <img className="h-100 rounded " src={fotoEquipa2} alt="fotoEquipa2" />
          </div>
          <div className="col mb-4" style={{ height: "38vw" }}>
            <img className="h-100 rounded" src={fotoEquipa3} alt="fotoEquipa3" />
          </div>
          <div className="col" style={{ height: "38vw" }}>
            <img className="h-100 rounded" src={fotoEquipa4} alt="fotoEquipa4" />
          </div>
        </div>
        {/* Fim Equipa */}

        {/* As nossa salas */}
        <h1 style={{ fontSize: "30px" }} className="Titulos text-uppercase">
          {languageData.equipaTitulo2}
        </h1>
        <p
          className="textoCorpo mb-0 pb-4"
          style={{ lineHeight: 2, fontSize: "18px" }}
          dangerouslySetInnerHTML={{ __html: languageData.equipaTexto }}
        ></p>

        <div className="row pb-5 ">
          <div className="col mb-4 text-center" style={{ height: "38vw" }}>
            <img className="h-75 rounded" src={fotoSalas1} alt="fotoSalas1" />
          </div>
          <div className="col mb-4 text-center" style={{ height: "38vw" }}>
            <img className="h-75 rounded" src={fotoSalas2} alt="fotoSalas2" />
          </div>
        </div>
        <div className="row pb-5 ">
          <div className="col text-center overflow-hidden" style={{ height: "38vw" }}>
            <img className="h-100 rounded " src={fotoSalas3} alt="fotoSalas3" />
          </div>
          <div className="col mb-4" style={{ height: "38vw" }}>
            <img className="h-100 rounded" src={fotoSalas4} alt="fotoSalas4" />
          </div>
          <div className="col" style={{ height: "38vw" }}>
            <img className="h-100 rounded" src={fotoSalas5} alt="fotoSalas5" />
          </div>
        </div>

        {/* Fim As nossa salas */}
      </div>
      <ScrollToTop smooth />
    </div>
  );
};

export default RestaurantePage;
